import styles from './home.module.css';

import React, { useState, useRef } from 'react';

import { buttonClickInteraction } from '../../helpers/interactions.ts';
import { useLoadedAnalytics, useQueryParamStorage } from '../../helpers/analytics.tsx';
import HowItWorks from './how_it_works/how_it_works.tsx';
import { AnimatedLineChart, AnimatedPieChart, exampleLoan } from './home_charts.tsx';
import FeatureHero from './feature_hero/feature_hero.tsx';
import { JoinHero, JoinHeroSection,  } from './join_hero/join_hero.tsx';
import MyModal from '../../components/my_modal/my_modal.tsx';
import { ButtonCTA, ButtonSecondary } from '../../components/button/button.tsx';
import { useNavigate } from 'react-router-dom';

const Home = () => {
    useLoadedAnalytics("home");
    useQueryParamStorage(); //get query params bringing visitor to the home page
    const navigate = useNavigate();

    const howItWorksRef = useRef<HTMLDivElement | null>(null);

	const [isShowWailtlistModal, setIsShowWaitlistModal] = useState(false);

    const onGetStarted = () => {
        let data = {
            buttonName: "get started"
        };
        buttonClickInteraction(data);
    }

    const onBorrowClick = () => {
        let data = {
            buttonName: "take out loan"
        };
        buttonClickInteraction(data);
        // navigate("/loan_application");
        // TODO: borrower data collection - initial loan steps
        setIsShowWaitlistModal(true);
    }

    const onLendClick = () => {
        let data = {
            buttonName: "become a lender"
        };
        buttonClickInteraction(data);
        // navigate("/lend");
        // TODO: lender data collection - initial lending criteria steps
        setIsShowWaitlistModal(true);
    }

    const onFinancialDashboardClick = () => {
        let data = {
            buttonName: "financial dashboard"
        };
        buttonClickInteraction(data);
        navigate("/account");
        // setIsShowWaitlistModal(true);
    }

    const onLearnMoreClick = () => {
        howItWorksRef!.current!.scrollIntoView({behavior: 'smooth'})
        let data = {
            buttonName: "learn more"
        };
        buttonClickInteraction(data);
    }

    const [showGetStartedOptions, setShowGetStartedOptions] = useState(false);

    return (
        <>
			<MyModal isShown={isShowWailtlistModal} setIsShown={setIsShowWaitlistModal} hasExitButton={true} Children={<JoinHero/>}/>
            <div className={styles.HomeContainer}>
                <div className={styles.HomeHeroTextContainer}>
                    <h1>Revolutionize Your Financial Freedom</h1>
                    <h2>Discover a seamless peer-to-peer lending platform that empowers you to take control of your financial future.</h2>
                    <div className={styles.ButtonContainer}>
                        <ButtonCTA onClick={()=>{onGetStarted(); setShowGetStartedOptions(!showGetStartedOptions)}}>Get Started</ButtonCTA>
                        {showGetStartedOptions ? <div className={styles.GetStartedDropDownOptionsContainer} onMouseLeave={()=>setShowGetStartedOptions(false)}>
                        <div className={styles.GetStartedOptionsContainer} onClick={onBorrowClick}>
                            <h3>Personal Loans</h3>
                            <p>Ultra Flexible $250-$10,000<span>1</span></p>
                        </div>
                        <div className={styles.GetStartedOptionsContainer} onClick={onLendClick}>
                            <h3>Investments</h3>
                            <p>Invest in real people</p>
                            </div>
                        <div className={styles.GetStartedOptionsContainer} onClick={onFinancialDashboardClick}>
                            <h3>Financial Tracking</h3>
                            <p>Industry Leading Dashboard</p>
                        </div>
                    </div> : null}
                        <ButtonSecondary onClick={onLearnMoreClick} className={styles.ButtonSecondary}>Learn More</ButtonSecondary>
                    </div>
                    
                </div>
                <div className={styles.HomeHeroImg}>
                    {/* Video background */}
                    <div className={styles.VideoBackgroundContainer}>
                        <video autoPlay muted loop playsInline className={styles.VideoBackground}>
                        <source src="assets/home/standin.webm" type="video/webm" />
                            Your browser does not support the video tag.
                        </video>
                        <div className={styles.Dimmer} />
                    </div>
                    {/* Chart containers */}
                    <div className={styles.ChartWrapper}>
                        <div className={`${styles.ChartContainer} ${styles.ChartOne}`}>
                            <AnimatedLineChart principal={exampleLoan.principal} totalInterest={exampleLoan.totalInterest} data={exampleLoan.data} />
                        </div>
                        <div className={`${styles.ChartContainer} ${styles.ChartTwo}`}>
                            <AnimatedPieChart principal={exampleLoan.principal} totalInterest={exampleLoan.totalInterest} data={exampleLoan.data} />
                        </div>
                    </div>
                </div>
            </div>
            <FeatureHero takeOutLoanClick={onLendClick} becomeLenderClick={onBorrowClick} financialDashboardClick={onFinancialDashboardClick}/>
            <JoinHeroSection />
            <HowItWorks ref={howItWorksRef}/>
        </>
    );
}

export default Home;