import './App.css';

import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Header from './components/header/header.tsx';
import Footer from './components/footer/footer.tsx';
import Home from './pages/home/home.tsx';
// import LoanApplication from './pages/loan_application/loan_application.tsx';
import Account from './pages/account/account.tsx';
// import LendHomePage from './pages/lend/lend.tsx';
import ResetPasswordForm from './pages/reset_password_form/reset_password_form.tsx';
import Survey from './pages/survey/survey.tsx';
import FAQ from './pages/faq/faq.tsx';
import PrivacyPolicy from './pages/privacy_policy/privacy_policy.tsx';
import TermsOfUse from './pages/terms_of_use/terms_of_use.tsx';
import Disclosures from './pages/disclosures/disclosures.tsx';
import Dashboard from './pages/dashboard/dashboard.tsx';

const App = () => {
	return (
		<div className="App">
			<BrowserRouter>
				<Header />
				<Routes>
					<Route path="/" element={<Home/>} />
					{/* <Route path="loan_application" element={<LoanApplication />} /> */}
					<Route path="dashboard" element={<Dashboard />} />
					{/* <Route path="lend" element={<LendHomePage />} /> */}
					<Route path="account" element={<Account />}/>

					<Route path="reset_password_form" element={<ResetPasswordForm/>}/>
					<Route path="survey" element={<Survey />} />
					<Route path="faq" element={<FAQ />} />
					<Route path="privacy_policy" element={<PrivacyPolicy />} />
					<Route path="terms_of_use" element={<TermsOfUse />} />
					<Route path="disclosures" element={<Disclosures />} />
				</Routes>
			<Footer/>
			</BrowserRouter>
		</div>
	);
}

export default App;
