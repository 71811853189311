import styles from './dashboard.module.css';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ResponsiveContainer, XAxis, YAxis, Legend, BarChart, Bar, Tooltip as ChartTooltip, CartesianGrid } from 'recharts';
import { User, UserImpl, getUserCookieData, isUserSignedIn } from '../../helpers/user.ts';
import { SignUpInModal } from '../../components/signin_up_modal/signin_up_modal.tsx';
import { get_loans_for_borrower, getTransactionHistory, TransactionData, makeAdditionalPayment, BankHistory } from '../../helpers/api.ts';
import { Loan } from '../../helpers/loan.ts';
import { sleep } from '../../helpers/sleep.ts';
import MyModal, { MessageModal } from '../../components/my_modal/my_modal.tsx';
import LoadingIcon from '../../components/loading_icon/loading_icon.tsx'

import { getWindowDimensions } from '../../helpers/page.ts';
import Carousel from '../../components/carousel/carousel.tsx';
import ExpensePieChart from './pie_chart/pie_chart.tsx';
import ExpenseBarChart from './bar_chart/bar_chart.tsx';
import {HighOverview, LiabilitiesOverview } from './overview/overview.tsx';
import DashboardDateRangePicker, { calculateOldestDate } from './date_range_picker/date_range_picker.tsx';
import TransactionTable from './transaction_table/transaction_table.tsx';

const LoanInfoCards = ({borrowerLoan}) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const intervalRef = useRef<number | null>(null);
    const startXRef = useRef<number | null>(null);

    useEffect(() => {
        startAutoRotation();
        return () => {
            if (intervalRef.current !== null) {
                clearInterval(intervalRef.current);
            }
        };
    }, []);

    const startAutoRotation = () => {
        intervalRef.current = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % 3);
        }, 5000);
    };

    const stopAutoRotation = () => {
        if (intervalRef.current !== null) {
            clearInterval(intervalRef.current);
        }
    };

    const handleDotClick = (index) => {
        setCurrentIndex(index);
        stopAutoRotation();
        startAutoRotation();
    };

    const handleCarouselClick = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % 3);
    }

    const handleTouchStart = (e: React.TouchEvent) => {
        startXRef.current = e.touches[0].clientX;
    };

    const handleTouchEnd = (e: React.TouchEvent) => {
        if (startXRef.current !== null) {
            const endX = e.changedTouches[0].clientX;
            const diffX = startXRef.current - endX;

            if (Math.abs(diffX) > 50) { // Threshold for swipe
                if (diffX > 0) {
                    // Swiped left
                    setCurrentIndex((prevIndex) => (prevIndex + 1) % 3);
                } else {
                    // Swiped right
                    setCurrentIndex((prevIndex) => (prevIndex - 1 + 3) % 3);
                }
                stopAutoRotation();
                startAutoRotation();
            }

            startXRef.current = null;
        }
    };

    const renderCards = (loan: Loan) => {
        return (
            <>
                <div className={`carousel-item ${currentIndex === 0 ? 'active' : ''}`}>
                    <h2>Upcoming</h2>
                    <h3>Pay Date:</h3>
                    <p>{loan.expectedPayments
                        .map(payment => ({ ...payment, date: new Date(payment.date) }))
                        .filter(payment => payment.date > new Date())
                        .sort((a, b) => a.date.getTime() - b.date.getTime())[0]?.date.toISOString().split('T')[0]
                    }</p>
                    <h3>Principal:</h3>
                    <p>${((loan.expectedPayments
                        .map(payment => ({ ...payment, date: new Date(payment.date) }))
                        .filter(payment => payment.date > new Date())
                        .sort((a, b) => a.date.getTime() - b.date.getTime())[0]?.principal / 1000)).toFixed(2)
                    }</p>
                    <h3>Interest:</h3>
                    <p>${((loan.expectedPayments
                        .map(payment => ({ ...payment, date: new Date(payment.date) }))
                        .filter(payment => payment.date > new Date())
                        .sort((a, b) => a.date.getTime() - b.date.getTime())[0]?.interest / 1000)).toFixed(2)
                    }</p>
                    <h3>Total:</h3>
                    <p>${(((loan.expectedPayments
                        .map(payment => ({ ...payment, date: new Date(payment.date) }))
                        .filter(payment => payment.date > new Date())
                        .sort((a, b) => a.date.getTime() - b.date.getTime())[0]?.interest / 1000)) +
                        ((loan.expectedPayments
                            .map(payment => ({ ...payment, date: new Date(payment.date) }))
                            .filter(payment => payment.date > new Date())
                            .sort((a, b) => a.date.getTime() - b.date.getTime())[0]?.principal / 1000))).toFixed(2)
                    }</p>
                </div>
                <div className={`carousel-item ${currentIndex === 1 ? 'active' : ''}`}>
                    <h2>Outstanding</h2>
                    <h3>Months:</h3>
                    <p>
                        {(() => {
                            const start = new Date(loan.loanStartDate);
                            const end = new Date(loan.loanEndDate);
                            const now = new Date();
                            const totalMonths = (end.getFullYear() - start.getFullYear()) * 12 + (end.getMonth() - start.getMonth());
                            const monthsPassed = (now.getFullYear() - start.getFullYear()) * 12 + (now.getMonth() - start.getMonth());
                            return totalMonths - monthsPassed;
                        })()}
                    </p>
                    <h3>Principal:</h3>
                    <p>
                        ${(() => {
                            const totalPrincipalPaid = loan.actualPayments.reduce((total, payment) => total + payment.principal, 0);
                            const outstandingPrincipal = (loan.loanTotalInCents - totalPrincipalPaid) / 1000;
                            return (outstandingPrincipal).toFixed(2);
                        })()}
                    </p>
                    <h3>Interest:</h3>
                    <p>
                        ${(() => {
                            const totalInterestPaid = loan.actualPayments.reduce((total, payment) => total + payment.interest, 0);
                            const outstandingInterest = (loan.expectedInterestInCents - totalInterestPaid) / 1000;
                            return (outstandingInterest).toFixed(2);
                        })()}
                    </p>
                    <h3>Total:</h3>
                    <p>${((() => {
                            const totalInterestPaid = loan.actualPayments.reduce((total, payment) => total + payment.interest, 0);
                            const outstandingInterest = (loan.expectedInterestInCents - totalInterestPaid) / 1000;
                            return (outstandingInterest);
                        })() + (() => {
                            const totalPrincipalPaid = loan.actualPayments.reduce((total, payment) => total + payment.principal, 0);
                            const outstandingPrincipal = (loan.loanTotalInCents - totalPrincipalPaid) / 1000;
                            return (outstandingPrincipal);
                        })()).toFixed(2)
                    }</p>
                </div>
                <div className={`carousel-item ${currentIndex === 2 ? 'active' : ''}`}>
                <h2>Completed</h2>
                    <h3>Months:</h3>
                    <p>
                        {(() => {
                            const start = new Date(loan.loanStartDate);
                            const now = new Date();
                            return (now.getFullYear() - start.getFullYear()) * 12 + (now.getMonth() - start.getMonth());
                        })()}
                    </p>
                    <h3>Principal:</h3>
                    <p>
                        ${(() => {
                            const totalPrincipalPaid = loan.actualPayments.reduce((total, payment) => total + payment.principal, 0);
                            return ((totalPrincipalPaid / 1000)).toFixed(2);
                        })()}
                    </p>
                    <h3>Interest:</h3>
                    <p>
                        ${(() => {
                            const totalInterestPaid = loan.actualPayments.reduce((total, payment) => total + payment.interest, 0);
                            return ((totalInterestPaid / 1000)).toFixed(2);
                        })()}
                    </p>
                    <h3>Total:</h3>
                    <p>${((() => {
                            const totalInterestPaid = loan.actualPayments.reduce((total, payment) => total + payment.interest, 0);
                            return ((totalInterestPaid / 1000));
                        })() + (() => {
                            const totalPrincipalPaid = loan.actualPayments.reduce((total, payment) => total + payment.principal, 0);
                            return ((totalPrincipalPaid / 1000));
                        })()).toFixed(2)
                    }</p>
                </div>
            </>
        );
    };

    return (
        <div className='BorrowerLoanCarouselContainer' 
            onMouseEnter={stopAutoRotation} 
            onMouseLeave={startAutoRotation} 
            onClick={handleCarouselClick}
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
        >
            <div className="carousel">
                <div className="carousel-inner" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
                    {renderCards(borrowerLoan)}
                </div>
                <div className="carousel-dots">
                    {[0, 1, 2].map((dotIndex) => (
                        <div
                            key={dotIndex}
                            className={`carousel-dot ${currentIndex === dotIndex ? 'active' : ''}`}
                            onClick={() => handleDotClick(dotIndex)}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}

// additional payment modal
// payment will be applied to principal if full amount is available in your account
// future payments will be recalculated on remaining balance after payment
// post payment to backend with loan ID or user ID?
// return success/failure message?
// reload latest user data
const AdditionalPaymentModal = ({index, loans, showModal, processCompleteHook}) => {

    const [paymentAmount, setPaymentAmount] = useState<string>("");
    const [paymentProcessing, setPaymentProcessing] = useState(false);

    const maxPayment = (loans[index]?.loanTotalInCents - loans[index]?.loanPaidInCents)/1000;
    const minPayment = 20;

    const handlePaymentAmountChange = (e) => {
        const value = e.target.value;
        // Remove all non-numeric characters except "."
        const numericValue = value.replace(/[^0-9.]/g, '');
        if (numericValue !== "") {
            setPaymentAmount(`$${numericValue}`);
        } else {
            setPaymentAmount("$");
        }
    };

    const formatPaymentAmount = () => {
        let numericValue = paymentAmount.replace(/[^0-9.]/g, '');
        if (numericValue === "") {
            setPaymentAmount("$0.00");
        } else {
            const floatAmount = parseFloat(numericValue);
            if (floatAmount > maxPayment) {
                setPaymentAmount(`$${maxPayment.toFixed(2)}`);
            } else {
                setPaymentAmount(`$${floatAmount.toFixed(2)}`);
            }
        }
    };

    const handlePayment = async () => {
        formatPaymentAmount();
        setPaymentProcessing(true);
        console.log(parseFloat(paymentAmount.slice(1)))
        await sleep(1000)
        const wasSuccessfulPayment = makeAdditionalPayment(loans[index]?.id, parseFloat(paymentAmount.replace('$', ''))*1000);
        setPaymentProcessing(false);
        processCompleteHook(wasSuccessfulPayment)
    }

    useEffect(()=>{
        setPaymentAmount("");
        setPaymentProcessing(false);
    },[showModal])

    return (
        <>
            <h2 className={styles.HeadingText}>
                Enter your additional payment amount
            </h2>
            <div className={styles.detailsContainer}>
                <p>
                    Payment will be applied to principal if this amount is available in your account.
                </p>
                <p>
                    Future payments will be recalculated on remaining balance after payment.
                </p>
                <p>
                    Min: ${minPayment}, Max: ${maxPayment}
                </p>
            </div>
            <input className={styles.AdditionalPaymentInput} type='text' value={paymentAmount} placeholder={`Min: $${minPayment}, Max: $${maxPayment}`} onChange={handlePaymentAmountChange} onBlur={formatPaymentAmount}/>
            <button className={styles.AdditionalPaymentButton} onClick={handlePayment} disabled={paymentProcessing}>{paymentProcessing ? "..." : "Pay"}</button>
        </>
    );
}

const ManageLoan = () => {

    const [showSignInModal, setShowSignInModal] = useState(false);
    const [showAdditionalPaymentModal, setShowAdditionalPaymentModal] = useState(false);
    const [userData, setUserData] = useState<User>(new UserImpl());
    const [borrowerLoans, setBorrowerLoans] = useState<Loan[]>([]);
    // there has to be a better way to do this
    const [selectedIndex, setSelectedIndex] = useState<number>(-1);

    const loadUserLoans = useCallback(() => {
        setUserData(getUserCookieData()!);
        if(userData.id) {
            get_loans_for_borrower(userData.id).then((resp)=>{
                // filter loans for isActive
                if(resp) {
                    resp = resp.filter((loan)=>loan.isActive)
                    setBorrowerLoans(resp);
                }
            });
        }
    }, [userData.id])

    useEffect(()=>{
        if(!isUserSignedIn()) {
            setShowSignInModal(true);
            return;
        }
        loadUserLoans();
    }, [loadUserLoans])

     // if login occured, check plaid access
     const userLoggedInCB = () => {
        setShowSignInModal(false);
        loadUserLoans();
    }

    const handleAdditionalPayment = (idx: number) => {
        setShowAdditionalPaymentModal(true);
        setSelectedIndex(idx);
    }

    const handleAdditionalPaymentComplete = (paymentDidCompelte: boolean) => {
        setShowAdditionalPaymentModal(false);
        setSelectedIndex(-1);
    }
    
    return (
        <div className={styles.ManageLoanContainer}>
            {showSignInModal ? 
            <SignUpInModal showModal={showSignInModal} modalEscapeCB={undefined} processCompleteHook={userLoggedInCB} /> 
            :
            borrowerLoans?.length === 0  || !borrowerLoans ? 
            <div>
                <h2>Nothing to see here...</h2>
                <button className={styles.TakeOutLoanButton}>Take out a Loan</button>
            </div>
            :
            <div className={styles.ManageLoanView}>
                <MyModal isShown={showAdditionalPaymentModal} setIsShown={setShowAdditionalPaymentModal} hasExitButton={true} Children={<AdditionalPaymentModal index={selectedIndex} loans={borrowerLoans} showModal={showAdditionalPaymentModal} processCompleteHook={handleAdditionalPaymentComplete}/>} />
                {borrowerLoans?.map((loan, idx)=>{
                    return (
                        <div className={styles.LoanView} key={idx}>
                            <h2 className={styles.LoanViewTitle}>${(loan.loanTotalInCents/1000).toFixed(2)} @ {loan.loanInterestRate/1000}%</h2>
                            <h3 className={styles.LoanViewStartingOn}>Starting on: {loan.loanStartDate.split('T')[0]} & Ending on: {loan.loanEndDate.split('T')[0]}</h3>
                            <h4 className={styles.LoanViewTotalInterest}>Total Interest: ${(loan.expectedInterestInCents/1000).toFixed(2)} <span className='LoanViewTotalInterestSpan'>(with no additional payments)</span></h4>
                            <div className={styles.DataContainer}>
                                <LoanInfoCards borrowerLoan={loan}/>
                                <div className={styles.ChartContainer}>
                                    <ResponsiveContainer width="100%" height="100%">
                                        <BarChart
                                            data={
                                                loan.expectedPayments.map((val, index)=>{
                                                    return {
                                                        name: loan.expectedPayments[index].date.split('T')[0],
                                                        interest: loan.expectedPayments[index].interest/1000,
                                                        principal: loan.expectedPayments[index].principal/1000,
                                                        paid: loan.actualPayments[index] ? (loan.actualPayments[index].interest + loan.actualPayments[index].principal)/1000 : 0,
                                                    };
                                                })
                                            }
                                        >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="name"/>
                                            <YAxis />
                                            <ChartTooltip />
                                            <Legend />
                                            <Bar dataKey="principal" stackId="a" fill="var(--pos-color)" />
                                            <Bar dataKey="interest" stackId="a" fill="var(--neg-color)" />
                                            <Bar dataKey="paid" stackId="b" fill="var(--cta-color)" />
                                        </BarChart>
                                    </ResponsiveContainer>
                                </div>
                            </div>
                            {loan.isActive ? <button className={styles.AdditionalPaymentButton} onClick={()=>handleAdditionalPayment(idx)}>Additional Payment</button> : null}
                        </div>
                    );
                })}
            </div>
            }
        </div>
    )
}

const DashboardComponent = () => {
    
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [isLoading, setIsLoading] = useState(true);
    const [showMessageModal, setShowMessageModal] = useState(false);
    const [messageModalText, setMessageModalText] = useState(<></>);

    const [oldestDate, setOldestDate] = useState<Date | number>(() => calculateOldestDate("month"));
    const [newestDate, setNewestDate] = useState<Date | number>(Date.now());

    let bankHistories = useRef<BankHistory[]>([]);
    let transactions = useRef<TransactionData[]>([]);
    const [filteredTransactions, setFilteredTransactions] = useState<TransactionData[]>([]);

    type OverviewFinancialData = {
        value: number;
        trend: "up" | "down" | ""; // Only allow these specific values
      };

    const [savings, setSavings] = useState<OverviewFinancialData>({ value: 0, trend: "" });
    const [expense, setExpense] = useState<OverviewFinancialData>({ value: 0, trend: "" });
    const [income, setIncome] = useState<OverviewFinancialData>({ value: 0, trend: "" });
    const [balance, setBalance] = useState<OverviewFinancialData>({ value: 0, trend: "" });

    const calculateTrend = (recentValue: number, previousValues: number[]) => {
        const average = previousValues.reduce((sum, val) => sum + val, 0) / previousValues.length;
        if (recentValue > average) {
            return "up";
        } else if (recentValue < average) {
            return "down";
        } else {
            return "";
        }
    };

    const filterTransactions = () => {
        const oldest = new Date(oldestDate);
        const newest = new Date(newestDate);
        const filtered = transactions.current.filter((transaction) => {
            transaction.amount = Math.abs(transaction.amount);
            const transactionDate = new Date(transaction.date);
            return transactionDate >= oldest && transactionDate <= newest;
        });
        setFilteredTransactions(filtered);
    };

    const updateCalculations = () => {
        // Calculate Expenses
        const expenseTransactions = filteredTransactions
            .filter((transaction) => transaction.personal_finance_category !== "INCOME" && transaction.personal_finance_category !== "TRANSFER_IN")
            .map((transaction) => transaction.amount);
        if (expenseTransactions.length > 1) {
            const recentExpense = expenseTransactions.reduce((sum, val) => sum + val, 0);
            const expenseTrend = calculateTrend(recentExpense, expenseTransactions.slice(1));
            setExpense({ value: recentExpense, trend: expenseTrend });
        }

        // Calculate Income
        const incomeTransactions = filteredTransactions
            .filter((transaction) => transaction.personal_finance_category === "INCOME" || transaction.personal_finance_category === "TRANSFER_IN")
            .map((transaction) => transaction.amount);

        if (incomeTransactions.length > 1) {
            // negative because incoming amounts are negative?
            const recentIncome = incomeTransactions.reduce((sum, val) => sum + val, 0);
            const incomeTrend = calculateTrend(recentIncome, incomeTransactions.slice(1));
            setIncome({ value: recentIncome, trend: incomeTrend });
        }

        // Calculate Balance
        const depositoryBalances = bankHistories.current
            .flatMap((history) => history.accounts)
            .filter((account) => account.subtype === "checking")
            .map((account) => account.available);

        if (depositoryBalances.length >= 1) {
            const recentBalance = depositoryBalances.reduce((sum, val) => sum + val, 0);
            const balanceTrend = calculateTrend(recentBalance, depositoryBalances.slice(1));
            setBalance({ value: recentBalance, trend: balanceTrend });
        }

        // Calculate Savings
        const savingBalances = bankHistories.current
            .flatMap((history) => history.accounts)
            .filter((account) => account.subtype === "savings")
            .map((account) => account.available);

        if (savingBalances.length >= 1) {
            const recentBalance = savingBalances.reduce((sum, val) => sum + val, 0);
            const balanceTrend = calculateTrend(recentBalance, savingBalances.slice(1));
            setSavings({ value: recentBalance, trend: balanceTrend });
        }
    };

    const dateRangeUpdate = (oldest: Date | number, newest: Date | number) => {
        setOldestDate(oldest);
        setNewestDate(newest);
    };

    // Handle resizing
    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Fetch transactions and calculate initial values
    useEffect(() => {
        setIsLoading(true);
        const user = getUserCookieData();
        if(user) {
            getTransactionHistory(user!.id).then((resp) => {
                if(resp.status === 200) {
                    console.log(resp.data)
                    bankHistories.current = resp.data as BankHistory[];
                    transactions.current = (resp.data as BankHistory[]).reduce((acc: TransactionData[], bankHistory) => {
                        return acc.concat(bankHistory.transactions);
                    }, []);
                    setIsLoading(false);
                    filterTransactions();
                    updateCalculations(); // Perform calculations after data is loaded
                } else if(resp.status === 422) {
                    // account error
                    setMessageModalText(<div><p>{resp.err}</p></div>);
                    setShowMessageModal(true);
                } else {
                    setMessageModalText(resp.err as unknown as JSX.Element);
                    setShowMessageModal(true);
                }
            });
        }
    }, []); // Empty dependency array ensures this runs only once after the component mounts

    // Re-filter and recalculate when the date range or transactions change
    useEffect(() => {
        filterTransactions(); // Re-filter whenever the date range changes
    }, [oldestDate, newestDate]);

    useEffect(() => {
        updateCalculations(); // Recalculate when filtered transactions change
    }, [filteredTransactions]);

    return (
        <div className={styles.DashboardContainer}>
            <MessageModal isShown={showMessageModal} setIsShown={setShowMessageModal} message={messageModalText} />
            <DashboardDateRangePicker onChangeCb={dateRangeUpdate} />
            {isLoading ? <LoadingIcon /> :
            <>
                {windowDimensions.width <= 768 ? (
                    <div className={styles.HighOverviewListContainer}>
                        <Carousel children={[
                            <HighOverview backgroundStyle={1} HighOverviewData={[
                                {title: "Balance", value: `$${balance.value.toFixed(2)}`, trend: balance.trend},
                                {title: "Savings", value: `$${savings.value.toFixed(2)}`, trend: savings.trend}
                            ]}/>,
                            <HighOverview backgroundStyle={2} HighOverviewData={[
                                {title: "Income", value: `$${income.value.toFixed(2)}`, trend: income.trend},
                                {title: "Expense", value: `$${expense.value.toFixed(2)}`, trend: expense.trend}
                            ]}/>,
                            <LiabilitiesOverview accounts={bankHistories.current.flatMap((history) => history.accounts)}/>
                        ]} />
                    </div>
                ) : (
                    <div className={styles.HighOverviewListContainer}>
                        <HighOverview backgroundStyle={1} HighOverviewData={[
                            {title: "Balance", value: `$${balance.value.toFixed(2)}`, trend: balance.trend},
                            {title: "Savings", value: `$${savings.value.toFixed(2)}`, trend: savings.trend}
                        ]}/>
                        <HighOverview backgroundStyle={2} HighOverviewData={[
                            {title: "Income", value: `$${income.value.toFixed(2)}`, trend: income.trend},
                            {title: "Expense", value: `$${expense.value.toFixed(2)}`, trend: expense.trend}
                        ]}/>
                        <LiabilitiesOverview accounts={bankHistories.current.flatMap((history) => history.accounts)}/>
                    </div>
                )}
                <div className={styles.PieAndTransactionsContainer}>
                    <TransactionTable data={filteredTransactions} />
                    <ExpensePieChart data={filteredTransactions} />
                </div>
                <div>
                    <ExpenseBarChart data={filteredTransactions} />
                </div>
            </>}
        </div>
    );
};

const Dashboard = () => {
    const [showSignInModal, setShowSignInModal] = useState(false);

    useEffect(()=> {
        if(!isUserSignedIn()) {
            setShowSignInModal(true);
            return;
        }
    }, [])

    const userLoggedInCB = () => {
        if(isUserSignedIn()) {
            setShowSignInModal(false);
        }
    }
    
    return (
        <div style={{minHeight:"100vh"}}>
            {showSignInModal ? <SignUpInModal showModal={showSignInModal} modalEscapeCB={undefined} processCompleteHook={userLoggedInCB} /> : <DashboardComponent />}
        </div>
    );
}

export default Dashboard;