import React, { useEffect, useState } from "react"
import styles from "./input.module.css"

export const InputCTA = (props: JSX.IntrinsicElements["input"]) => {
    const { children, ...rest } = props;
    return (
        <input className={`${styles.Input} ${styles.CTA} ${styles.CTAHover}`} {...rest}>{children}</input>
    );
}

export const InputPrimary = (props: JSX.IntrinsicElements["input"]) => {
    const { children, ...rest } = props;
    return (
        <input className={`${styles.Input} ${styles.Primary} ${styles.PrimaryHover}`} {...rest}>{children}</input>
    );
}

export const InputSecondary = (props: JSX.IntrinsicElements["input"]) => {
    const { children, ...rest } = props;
    return (
        <input className={`${styles.Input} ${styles.Secondary} ${styles.SecondaryHover}`} {...rest}>{children}</input>
    );
}

export const Checkbox = (props: JSX.IntrinsicElements["input"]) => {
    const { children, ...rest } = props;
    return (
        <input type='checkbox' className={`${styles.Checkbox}`} {...rest}>{children}</input>
    );
}

// Define the props interface
interface RangeSliderProps {
    min: number;
    max: number;
    step?: number;
    stepValues?: number[];
    onChange?: (value: number) => void;
    controlledValue?: number;
    initialValue?: number;
}

export const RangeSlider = ({ min, max, step = 1, stepValues, onChange, controlledValue, initialValue, ...rest}: RangeSliderProps) => {
    // const { children, ...rest } = props;
    // Use controlled or uncontrolled pattern for the value state
    const [internalValue, setInternalValue] = useState<number>(initialValue !== undefined ? initialValue : min);
    const [isControlled, setIsControlled] = useState(true); // Track if we are using the controlled value or internal state

  useEffect(() => {
    // If controlled value is provided, sync with internal state and use controlled
    if (controlledValue !== undefined) {
      setInternalValue(controlledValue);
      setIsControlled(true); // Switch back to using controlled value
    }
  }, [controlledValue]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = parseFloat(e.target.value);
        let closestValue = newValue;
        if (stepValues && stepValues.length > 0) {
            // If stepValues are provided, snap to the closest step value
            closestValue = stepValues.reduce((prev, curr) => Math.abs(curr - newValue) < Math.abs(prev - newValue) ? curr : prev);
        }
        // Update internal state if uncontrolled
        if (controlledValue === undefined) {
            setInternalValue(closestValue);
        }
        // Call the onChange callback if provided
        if (onChange) {
            onChange(closestValue);
        }
    };

    return (
        <div className={styles.RangeContainer}>
            <input
                type="range"
                min={min}
                max={max}
                step={stepValues ? 0.01 : step} // Use small step when stepValues is active
                value={isControlled && controlledValue !== undefined ? controlledValue : internalValue}
                onChange={handleChange}
                className={`${styles.RangeSlider}`} 
                {...rest}
            />
            <div className={styles.RangeLabel}>
                <span>{isControlled && controlledValue !== undefined ? controlledValue : internalValue}</span>
            </div>
        </div>
    );
}
