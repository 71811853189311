import './signin_up_modal.css';

import React, { useEffect, useState } from 'react';
import { sign_in, sign_up, forgot_password } from '../../helpers/api.ts';
import { initSaveUserDataToCookieOnly } from '../../helpers/user.ts';

const enum LogInStates {
    "valid",
    "invalid",
    "awaiting",
}

const enum SignInUp {
    "signup",
    "signin"
}

export const SignUpInModal = ({showModal, modalEscapeCB, processCompleteHook}: {showModal: boolean, modalEscapeCB?: ()=>void, processCompleteHook?: ()=>void}) => {
    
    const [userEmail, setUserEmail] = useState("");
    const [userPassword, setUserPassword] = useState("");
    const [userConfirmPassword, setUserConfirmPassword] = useState("");
    const [signUpInState, setSignUpInState] = useState(LogInStates.invalid);
    const [signInOrUp, setSignInOrUp] = useState(SignInUp.signin);
    const [awaitingLogIn, setIsAwaitingLogIn] = useState(false);
    const [signUpInHover, setSignUpInHover] = useState(false);

    const onMouseEnter = () => {
        setSignUpInHover(true);
    }

    const onMouseLeave = () => {
        setSignUpInHover(false);
    }

    const onUserEmailChange = (e: { target: { value: React.SetStateAction<string>; }; }) => {
        setUserEmail(e.target.value);
    }

    const onUserPasswordChange = (e: { target: { value: React.SetStateAction<string>; }; }) => {
        setUserPassword(e.target.value);
    }

    const onUserConfirmPasswordChange = (e: { target: { value: React.SetStateAction<string>; }; }) => {
        setUserConfirmPassword(e.target.value);
    }

    useEffect(()=> {
        const validEmail = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(userEmail).valueOf();
        // validate info for neat effects
        if(signInOrUp === SignInUp.signin && userPassword.length > 0 && validEmail) {
            setSignUpInState(LogInStates.valid);
        } else if(signInOrUp === SignInUp.signup && userConfirmPassword === userPassword && userConfirmPassword.length > 0 && userPassword.length > 0 && validEmail) {
            setSignUpInState(LogInStates.valid);
        } else {
            setSignUpInState(LogInStates.invalid);
        }
    }, [signInOrUp, userConfirmPassword, userEmail, userPassword])

    const onSubmit = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        if(!(signUpInState === LogInStates.valid)) return;
        setIsAwaitingLogIn(true);
        try {
            if(signInOrUp === SignInUp.signin) {
                let resp = await sign_in(userEmail, userPassword);
                if(resp.body === "invalid") {
                    alert("Invalid Password or Account not found.")
                } else {
                    console.log(resp)
                    initSaveUserDataToCookieOnly(resp);
                    if(processCompleteHook) processCompleteHook()
                }
            } else if(signInOrUp === SignInUp.signup) {
                let resp = await sign_up(userEmail, userPassword);
                if(resp.body === "invalid") {
                    alert("Failed to create account - if you believe this is an error, contact support");
                } else {
                    initSaveUserDataToCookieOnly(resp);
                    if(processCompleteHook) processCompleteHook()
                }
            }
        } catch (error) {
            console.log(error);
        }
        setIsAwaitingLogIn(false);
    }

    const onForgotPassword = () => {
        const validEmail = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(userEmail).valueOf();
        if(!validEmail) {
            // eslint-disable-next-line no-restricted-globals
            confirm("Please enter the email associated with your account");
        } else {
            forgot_password(userEmail);
            // eslint-disable-next-line no-restricted-globals
            confirm("An email with reset instructions has been sent to your email");
        }
    }

    const onCreateNewAccount = () => {
        signInOrUp === SignInUp.signin ? setSignInOrUp(SignInUp.signup) : setSignInOrUp(SignInUp.signin);
    }

    return (
        <dialog className="SignupModal" open={showModal}>
            <div className='InsideContainer'>
                {modalEscapeCB ? <button className='Close' onClick={modalEscapeCB}>❌</button> : null}
                <h1 className='HeadingText'>
                    Sign {signInOrUp ? "In" : "Up"}
                </h1>
                <form className='Form' onSubmit={onSubmit}>
                    <input className='Input' type='email' onChange={onUserEmailChange} placeholder='your@email.com'/>
                    {signInOrUp ? 
                        <input className='Input' type='password' onChange={onUserPasswordChange} placeholder='password' minLength={8}/>
                    :
                        <>
                            <input className='Input' type='password' onChange={onUserPasswordChange} placeholder='new password' minLength={8}/>
                            <input className='Input' type='password' onChange={onUserConfirmPasswordChange} placeholder='confirm password' minLength={8}/>
                        </>
                    }
                    <input className='Submit' 
                        disabled={awaitingLogIn}
                        style={{
                            color: signUpInState === LogInStates.valid ? `var(--cta-color)` : signUpInState === LogInStates.awaiting ? `var(--text-color)` : `var(--neg-color)`,
                            scale: signUpInHover ? signUpInState === LogInStates.valid ? `110%` : `90%` : `100%`
                        }}
                        type="submit" 
                        value={awaitingLogIn ? "..." :"Enter"}
                        onMouseEnter={onMouseEnter} 
                        onMouseLeave={onMouseLeave}
                    />
                </form>
                <div className='CreateNewAccount' onClick={onCreateNewAccount}>
                    {signInOrUp ? "Create new account" : "Existing Sign In"}
                </div>
                <div className='ForgotPassword' onClick={onForgotPassword}>
                    forgot password?
                </div>
            </div>
        </dialog>
    );
}
