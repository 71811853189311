import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import styles from "./join_hero.module.css";

import SHA256 from 'crypto-js/sha256';
import { joinWaitlist } from '../../../helpers/api.ts';
import { generateFingerprint } from '../../../helpers/fingerprint.ts';
import { ButtonCTA } from '../../../components/button/button.tsx';
import { SelectCTA } from '../../../components/select/select.tsx';
import { InputPrimary } from '../../../components/input/input.tsx';

export const JoinHero = () => {
    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [interest, setInterest] = useState("");
    const [referredBy, setReferredBy] = useState("");
    const [isValidEmail, setIsValidEmail] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let v = e.target.value;
        setEmail(v);
    }

    const handleReferralChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let v = e.target.value;
        setReferredBy(v);
    }

    useEffect(()=>{
        const re = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;
        const emailValid = re.test(String(email).toLowerCase());
        if(emailValid && interest !== "") {
            setIsValidEmail(true);
        } else {
            setIsValidEmail(false);
        }
    }, [email, interest]);

    const learnMoreClick = () => {
        navigate("/faq?q=referral program");
        window.scrollTo(0, 0);
    }

    const onTermsClick = () => {
        navigate("/terms_of_use");
        window.scrollTo(0, 0);
    }

    const onPrivacyClick = () => {
        navigate("/privacy_policy");
        window.scrollTo(0, 0);
    }

    const submitWaitlist = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        const fingerprint = await generateFingerprint();
        const status = await joinWaitlist(email, interest, fingerprint, referredBy);
        switch (status) {
            case 200:
                if (sessionStorage.getItem("utm_source") === 'TikTok') {
                    if (window.ttq) {
                        const hashedEmail = SHA256(email).toString();
                        const hashedFingerprint = SHA256(fingerprint).toString();
                        window.ttq.identify({
                            "email": hashedEmail,
                            "external_id": hashedFingerprint
                        });
                        window.ttq.track('SubmitForm', {
                            "contents": [
                                {
                                    "content_id": "waitlist_submission",
                                    "content_name": "Waitlist Form"
                                }
                            ]
                        });
                    }
                } else if (sessionStorage.getItem("utm_source") === 'Google') {
                    // gtag('event', 'conversion', {
                    // 	'send_to': 'AW-713235272/0203CJmz0MgZEMi2jNQC'
                    // });
                }
                navigate(window.location.pathname);
                alert("Your interest is sincerely appreciated. We look forward to letting you know when Crowdcash is available! Please check your spam folder for a welcome email.");
                break;
            case 409:
                alert("We may have already recorded a response from you. If not please contact us about this error directly at crowdcash@crowdcash.cc to help resolve the issue.");
                break;
            default:
                alert("Something went wrong... Please try refreshing this page and trying again or please contact us directly at crowdcash@crowdcash.cc to help us resolve the issue.");
                break;
        }
        setEmail("");
        setInterest("");
        setIsSubmitting(false);
        // window.scrollTo({top: 0, behavior: 'smooth'});
    }

    return (
        <div className={styles.JoinHeroContainer}>
            <h1>Join the Fintech Revolution</h1>
            <h3>We're excited to launch! Until then, please sign up for our waitlist to help our developement journey! It comes with great referral benefits. <small onClick={learnMoreClick}>learn more</small></h3>
            <form className={styles.WaitlistForm}>
                <div className={styles.SelectContainer}>
                    <label>Interested In: </label>
                    <SelectCTA required defaultValue={interest} onChange={(e)=>setInterest(e.target.value)}>
                        <option value=""></option>
                        <option value="both">Both</option>
                        <option value="borrowing">Borrowing</option>
                        <option value="lending">Lending</option>
                    </SelectCTA>
                </div>
                <InputPrimary type='email' placeholder='your@email.com' onChange={handleEmailChange} value={email} required/>
                <InputPrimary data-tooltip-id="referralCodeToolTip" type='text' minLength={6} maxLength={6} placeholder='referral code' onChange={handleReferralChange} value={referredBy}/>
                <ButtonCTA onClick={submitWaitlist} disabled={!isValidEmail || isSubmitting} >{isSubmitting ? "..." : "Register"}</ButtonCTA>
            </form>
            <div className={styles.SmallContainer}>
                <small>Your email won't be spammed or sold</small>
                <small>By registering you agree to our <span onClick={onTermsClick}>terms</span> and <span onClick={onPrivacyClick}>privacy policy</span></small>
            </div>
        </div>
    );
}

export const JoinHeroSection = ()=> {

    const wrapperRef = useRef(null);
  
    const [wrapperVisible, setWrapperVisible] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        if (entry.target === wrapperRef.current) {
                            setWrapperVisible(true);
                        }
                    } else if(!entry.isIntersecting) {
                        if (entry.target === wrapperRef.current) {
                            setWrapperVisible(false);
                        }
                    }
                });
            },
            { threshold: 0.3 }
        );

        if (wrapperRef.current) observer.observe(wrapperRef.current);

        return () => {
            if (wrapperRef.current) observer.unobserve(wrapperRef.current);
        };
    }, []); 

    return (
        <div ref={wrapperRef} className={`${styles.Wrapper} ${wrapperVisible ? styles.fadeIn : styles.fadeOut}`}>
            <JoinHero />
        </div>
    );
}
