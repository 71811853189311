import './header.css';
import {ReactComponent as MenuIcon} from './menu_icon.svg';
import {ReactComponent as MenuAltIcon} from './menu_alt_icon.svg';
import React, { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
import { SignUpInModal } from '../signin_up_modal/signin_up_modal.tsx';
import { clearUserData, isUserSignedIn } from '../../helpers/user.ts';
import { useNavigate } from 'react-router-dom';

const Menu = ({signedIn, handleMenuPress, LinkClickCB}: {signedIn: boolean, handleMenuPress: () => void, LinkClickCB: (to: string) => void}) => {
    return (
        <div className='HeaderMenu'>
            <div className='HeaderMenuButton' onClick={handleMenuPress}>
                {signedIn ? <p>Sign Out</p> : <p>Sign in/up</p>}
            </div>
            <div className='HeaderMenuButton' onClick={()=>LinkClickCB("dashboard")}>
                <div className='HeaderMenuHref'>Dashboard</div>
            </div>
            {/* 
            <div className='HeaderMenuButton' onClick={()=>LinkClickCB("loan_application")}>
                <div className='HeaderMenuHref'>Open A Loan</div>
            </div>
            
            <div className='HeaderMenuButton' onClick={()=>LinkClickCB("lend")}>
                <div className='HeaderMenuHref'>Lenders</div>
            </div> */}
            <div className='HeaderMenuButton' onClick={()=>LinkClickCB("account")}>
                <div className='HeaderMenuHref'>Account</div>
            </div>
            {/* <div className='HeaderMenuButton'>
                <a href="/survey" className='HeaderMenuHref'>Survey</a>
            </div> */}
            <div className='HeaderMenuButton'>
                <a href="/faq" className='HeaderMenuHref'>FAQ</a>
            </div>
        </div>
    )
}

const Header = () => {
    const navigate = useNavigate();
    const [showMenu, setShowMenu] = useState(false);
    const [showModal, isShowModal] = useState(false);
    const [_isUserSignedIn, setIsUserSignedIn] = useState(false);

    const handleMenuPress = () => {
        setShowMenu(!showMenu);
    }

    const handleShowSignInModal = () => {
        setShowMenu(false);
        isShowModal(!showModal);
        if(_isUserSignedIn) {
            clearUserData();
            isShowModal(false);
            navigate("/");
        }
    }

    const handleMouseLeave = () => {
        setShowMenu(false);
    }

    const handleLinkClick = (to: string) => {
        window.scrollTo(0, 0);
        setShowMenu(false);
        navigate(to);
    }

    useEffect(()=>{
        setIsUserSignedIn(isUserSignedIn())
    }, [showMenu])

    return (
        <>
            <div className='Header' onMouseLeave={handleMouseLeave}>
                <div className='HeaderLogoContainer'>
                        <p className='HeaderLogoTitle' onClick={()=>{navigate("/")}}>CrowdCash</p>
                </div>
                <div className='HeaderIconContainer'>
                    {showMenu ? <MenuAltIcon className='HeaderMenuSVG' onClick={handleMenuPress}/> : <MenuIcon className='HeaderMenuSVG' onClick={handleMenuPress}/>}
                    {showMenu ? <Menu signedIn={_isUserSignedIn} handleMenuPress={handleShowSignInModal} LinkClickCB={handleLinkClick}/> : null}
                </div>
            </div>
            <SignUpInModal showModal={showModal} modalEscapeCB={handleShowSignInModal} processCompleteHook={handleShowSignInModal}/>
        </>
    );
}

export default Header;