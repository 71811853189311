import styles from './BankManagement.module.css';
import React, { useEffect, useState } from "react";
import { PlaidLinkOptions, usePlaidLink } from 'react-plaid-link';
import { getUserCookieData, getServerUserData, PlaidLinkedBank, PlaidBankAccount, isUserSignedIn } from '../../helpers/user.ts';
import { getCreatedLinkToken, sendTokenForExchange } from '../../helpers/api.ts';
import LoadingIcon from '../loading_icon/loading_icon.tsx';
import { ButtonSecondary } from '../button/button.tsx';
import { Checkbox } from '../input/input.tsx';
import Carousel from '../carousel/carousel.tsx';
import { ConfirmationModal } from '../my_modal/my_modal.tsx';

export interface BankManagementProps {
    onDataChange?: (plaidLinkedBanks: PlaidLinkedBank[])=>void; //cb to handle when internal data changes
    onAddtnlBtn?: ()=>void; // definitely bad logic - should use HOC? - adds "go back" button essentially
    isLoading?: (is: boolean)=>void; //CB to handle internal loading state to prevent mis-saving - probably bad logic here
    enableDashboard?: JSX.Element //EnableDashboard component wil logic to handle enable, disable, and billing
}

const BankAccount = ({account, idx, idy, handleAccountChange}: {account: PlaidBankAccount, idx: number, idy: number, handleAccountChange: (idx: number, idy: number)=>void}) => {
    return (
        <div className={styles.AccountContainer} style={{'--accent-color-1': `var(--accent-values-${idx + 2})`, background: `linear-gradient(210deg, rgba(var(--accent-values-${idx + 2}), 0.5) 0%, transparent 40%)`}}>
            <label className={styles.AccountTitle}>{account.accountName}</label>
            {account.canBeDepositWithdraw ? 
            <div className={styles.AccountInputContainer}>
                <p className={styles.PositiveP}>Set deposit/withdraw account</p>
                <Checkbox name='plaidBankAccounts' checked={account.isDepositWithdraw} onChange={()=>{handleAccountChange(idx, idy)}} />
            </div>
            :
            <div className={styles.AccountInputContainer}>
                <p className={styles.NegativeP}>Ineligible account</p>
                <Checkbox name='plaidBankAccounts' checked={false} disabled readOnly />
            </div>
            }
        </div>
    );
}

const BankManagement = (props: BankManagementProps) => {
    const [plaidLinkedBanks, setPlaidLinkedBanks] = useState<PlaidLinkedBank[]>(getUserCookieData() ? getUserCookieData()!.plaidLinkedBanks : []);
    const [isLoadingBank, setIsLoadingBank] = useState(false);
    
    // Track the index of the bank to be removed (null if no modal is shown)
    const [removeBankIndex, setRemoveBankIndex] = useState<number | null>(null);

    useEffect(() => {
        const getLinkToken = async () => {
            if (isUserSignedIn()) {
                const data = await getCreatedLinkToken(getUserCookieData()!.id);
                setLinkToken(data.link_token);
            }
        };
        getLinkToken();
    }, []);

    const [linkToken, setLinkToken] = useState<string | null>(null);
    var plaidLinkOptions: PlaidLinkOptions = {
        token: linkToken,
        onSuccess: (public_token, metadata) => {
            setIsLoadingBank(true);
            getCreatedLinkToken(getUserCookieData()!.id).then((data) => {
                setLinkToken(data.link_token);
            });
            sendTokenForExchange(public_token, metadata.institution!.name, metadata.institution!.institution_id, getUserCookieData()!.id).then((resp) => {
                getServerUserData(getUserCookieData()!.email, getUserCookieData()!.id).then((resp) => {
                    setPlaidLinkedBanks(resp.plaidLinkedBanks);
                    setIsLoadingBank(false);
                });
            });
        }
    };
    var { open, ready } = usePlaidLink(plaidLinkOptions);

    const LinkBank = async () => {
        if (ready) {
            open();
        }
    };

    const handleAccountChange = (idx: number, idxy: number) => {
        let updatedPlaidBanks = plaidLinkedBanks.map((bank, bankIdx) => {
            let updatedAccounts = bank.accounts.map((account, accountIdx) => {
                if (bankIdx === idx && accountIdx === idxy) {
                    return {
                        ...account,
                        isDepositWithdraw: true,
                    };
                }
                return {
                    ...account,
                    isDepositWithdraw: false,
                };
            });

            return {
                ...bank,
                accounts: updatedAccounts,
                isMainBank: bankIdx === idx,
            };
        });

        setPlaidLinkedBanks(updatedPlaidBanks);
    };

    const handleBankDelete = (idx: number) => {
        console.log(`delete bank ${idx}`);
        // Proceed with deleting the bank at index `idx`...
        setPlaidLinkedBanks(plaidLinkedBanks.filter((_, bankIdx) => bankIdx !== idx));
    };

    const bankDeleteModalChild = () => {
        return (
            <>
                <h3>Are you Sure?</h3>
                <p style={{ textAlign: 'left' }}>
                    Removing a bank that is marked as "deposit/withdraw" will fail if no other valid account can be "deposit/withdraw".
                </p>
                <p style={{ textAlign: 'left' }}>
                    If using the dashboard, transactions from this bank will no longer be monitored.
                </p>
            </>
        );
    };

    useEffect(() => {
        if (props.onDataChange) props.onDataChange(plaidLinkedBanks);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [plaidLinkedBanks]);

    return (
        <div className={styles.BankManagementContainer}>
            <div className={styles.ButtonContainer}>
                {props.onAddtnlBtn ? (
                    <ButtonSecondary onClick={() => { props.onAddtnlBtn!(); }}>Go Back</ButtonSecondary>
                ) : null}
                {plaidLinkedBanks.length > 0 ? (
                    <ButtonSecondary onClick={LinkBank}>
                        {plaidLinkedBanks.length > 1 ? 'Manage Banks' : 'Manage Bank'}
                    </ButtonSecondary>
                ) : (
                    <ButtonSecondary onClick={LinkBank}>Link Bank</ButtonSecondary>
                )}
            </div>
            {plaidLinkedBanks.length > 0 ? <h3>Linked Banks</h3> : null}
            <div className={styles.BanksAllContainer} style={plaidLinkedBanks.length < 2 ? { gridTemplateColumns: 'auto' } : {}}>
                {plaidLinkedBanks.map((bank, idx) => (
                    <div className={styles.BankContainer} key={idx}>
                        <div className={styles.BankNameRemoveContainer}>
                            <h2>{bank.bankName}</h2>
                            <ConfirmationModal
                                isShown={removeBankIndex === idx} // Show modal only for the correct index
                                setIsShown={() => setRemoveBankIndex(null)} // Close the modal
                                confirmCB={() => handleBankDelete(idx)} // Pass the correct bank index for deletion
                                rejectCB={() => setRemoveBankIndex(null)} // Reject/close modal
                                children={[bankDeleteModalChild()]}
                            />
                            <button
                                className={styles.RemoveBankButton}
                                onClick={() => setRemoveBankIndex(idx)} // Set the modal to show for this bank
                            >
                                ❌
                            </button>
                        </div>
                        <div className={styles.AccountAllContainer}>
                            <Carousel
                                children={bank.accounts.map((account, idy) => (
                                    <BankAccount
                                        key={idx + 1 * idy + 1}
                                        account={account}
                                        idx={idx}
                                        idy={idy}
                                        handleAccountChange={handleAccountChange}
                                    />
                                ))}
                            />
                        </div>
                    </div>
                ))}
                {isLoadingBank ? (
                    <div className={styles.LoadingIconContainer}>
                        <LoadingIcon />
                    </div>
                ) : null}
            </div>
        </div>
    );
};


export default BankManagement;