import './survey.css';

import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";

import { RespondantInterestType } from '../../models/questionnaire.ts';

import MultiChoiceRadio from '../../components/multi_choice_radio/multi_choice_radio.tsx';
import EmailInput from '../../components/email_input/email_input.tsx';
import { sleep } from '../../helpers/sleep.ts';
import { buttonClickInteraction } from '../../helpers/interactions.ts';
import { useLoadedAnalytics } from '../../helpers/analytics.tsx';
import { saveSurvey } from '../../helpers/api.ts';
import { generateFingerprint } from '../../helpers/fingerprint.ts';

const MultiChoiceQuestions = {
    title: "How much would you be interested in borrowing/lending?",
    altTitle: "How long would you be interested in borrowing/lending?",
    amounts: ["$1 to $1,000", "$1,001 to $5,000", "$5,001 to 20,000", "$20,001+"],
    durations: ["bewteen 1 and 6 months", "between 7 and 12 months", "between 1 and 2 years", "more than 2 years"],
}

const CreditScoreQuestions = {
	SelfQuestion: "(Optional) Approximately, what's your credit score?",
    LendQuestion: "What's the lowest credit score you'd lend to?",
    scoreList: ["800 to 850", "740 to 799", "670 to 739", "580 to 669", "300 to 579"],
}

const Survey = () => {
	// useScrollAnalytics("survey");
    useLoadedAnalytics("survey");

	useEffect(()=>{
		window.scrollTo(0, 0);
	}, []);

	
    const [respondantStance, setRespondantStance] = useState(-1);
	const [showLendingQuestion, setshowLendingQuestion] = useState(false);
	const [showBorrowQuestion, setshowBorrowQuestion] = useState(false);
	const [loanAmount, setLoanAmount] = useState(-1);
	const [loanTerm, setLoanTerm] = useState(-1);
	const [visitorCreditScore, setVisitorCreditScore] = useState(-1);
	const [lendCreditScore, setLendCreditScore] = useState(-1);
	const [respondantEmail, setRespondantEmail] = useState("");

	const navigate = useNavigate();

	const handleRespondantInterestTypeQuestionSelection = (multiChoiceArray) => {
		if(multiChoiceArray[0] || multiChoiceArray[2]) {
			setshowLendingQuestion(true)
		} else {
			setshowLendingQuestion(false)
		}
		if(multiChoiceArray[1] || multiChoiceArray[2]) {
			setshowBorrowQuestion(true)
		} else {
			setshowBorrowQuestion(false)
		}
		setRespondantStance(multiChoiceArray.indexOf(true));
	}

	const onFormSubmit = async (e) => {
		e.preventDefault();
		const responseData = {
			visitorIpAddr: "",
			interestStance: respondantStance,
			loanAmount: loanAmount,
			loanTerm: loanTerm,
			visitorCreditScore: visitorCreditScore,
			lendingCreditScore: lendCreditScore,
			emailAddress: respondantEmail,
			fingerprint: generateFingerprint()
		}
		// send data to api for tracking
		try {
			saveSurvey(JSON.stringify(responseData))
			.then(status=>{
				switch (status) {
					case 200:
						alert("Your time and interest are sincerely appreciated. Thank you for helping make Crowdcash a better service!");
						break;
					case 409:
						alert("We may have already recorded a response from you. If not please contact us about this error directly at crowdcash@crowdcash.cc to help resolve the issue.");
						break;
					default:
						alert("Something went wrong... If not please try refreshing this page and trying again or please contact us directly at crowdcash@crowdcash.cc to help us resolve the issue.");
						break;
			}})
			.then(()=>{
				sleep(3000).then(()=>{
					navigate("/faq");
				})
			});
		} catch (error) {
			console.log(error);
			alert("An error occured. Please try again.");
		}
	}

	const handleSetLoanAmount = (options: boolean[]) => {
		const selection = options.indexOf(true);
		let data = {
			buttonName: "Select loan amount",
			choice: selection
		};
		buttonClickInteraction(data);
		setLoanAmount(selection);
	}

	const handleSetLoanTerm = (options: boolean[]) => {
		const selection = options.indexOf(true);
		let data = {
			buttonName: "Select loan term",
			choice: selection
		};
		buttonClickInteraction(data);
		setLoanTerm(selection);
	}

	const handleSetLendScore = (options: boolean[]) => {
		const selection = options.indexOf(true);
		let data = {
			buttonName: "Select loan term",
			choice: selection
		};
		buttonClickInteraction(data);
		setVisitorCreditScore(selection);
	}

	const handleSetVisitorScore = (options: boolean[]) => {
		const selection = options.indexOf(true);
		let data = {
			buttonName: "Select loan term",
			choice: selection
		};
		buttonClickInteraction(data);
		setLendCreditScore(selection);
	}

	return (
		<div className="SurveyContainer">
			<form className="SurveyForm">
				<MultiChoiceRadio title={RespondantInterestType.question} choices={RespondantInterestType.types} oneChoice={true} setSelectedValues={handleRespondantInterestTypeQuestionSelection}/>
				
				<MultiChoiceRadio title={MultiChoiceQuestions.title} choices={MultiChoiceQuestions.amounts} oneChoice={true} setSelectedValues={handleSetLoanAmount}/>
				<MultiChoiceRadio title={MultiChoiceQuestions.altTitle} choices={MultiChoiceQuestions.durations} oneChoice={true} setSelectedValues={handleSetLoanTerm}/>
				{showLendingQuestion ? <MultiChoiceRadio title={CreditScoreQuestions.LendQuestion} choices={CreditScoreQuestions.scoreList} oneChoice={true} setSelectedValues={handleSetLendScore}/> : null}
				{showBorrowQuestion ? <MultiChoiceRadio title={CreditScoreQuestions.SelfQuestion} choices={CreditScoreQuestions.scoreList} oneChoice={true} setSelectedValues={handleSetVisitorScore}/> : null}

				<EmailInput title='Enter your email (optional)' setValidEmail={setRespondantEmail}/>
				<input className='SubmitButton' type="submit" onClick={onFormSubmit}/>
			</form>
		</div>
	);
}

export default Survey;