import './multi_choice_radio.css';
import React, { useState } from 'react';

interface MultiChoiceRadioProps {
    title: string
    choices: string[]
    oneChoice: boolean;
    setSelectedValues: (values: boolean[])=>void
}

const MultiChoiceRadio = ({title, choices, oneChoice, setSelectedValues}: MultiChoiceRadioProps) => {

	const [selectedOptions, setSelectedOptions] = useState(choices.map(() => false));

	const handleOptionChange = (e, index) => {
		e.preventDefault();
		let newSelectedOptions = [...selectedOptions];
        if (oneChoice) {
            // If oneChoice is true, unselect all other options when one is selected
            for (let i = 0; i < choices.length; i++) {
              newSelectedOptions[i] = false;
            }
        }
        newSelectedOptions[index] = !newSelectedOptions[index];
        setSelectedOptions(newSelectedOptions);
        if(setSelectedValues) setSelectedValues(newSelectedOptions);
	};

	return (
		<div className='MultiChoiceRadioContainer'>
			<p className='MultiChoiceRadioTitle'>{title}</p>
			<div className="MultiChoiceRadioOptions">
                {choices.map((choice, index) => {
                    return (
                        <button 
                            key={index}
                            className={`MultiChoiceButtonOption ${selectedOptions[index] ? 'selected' : ''}`} 
                            onClick={(e)=>{handleOptionChange(e, index)}}
                            >
                                {choice}
                        </button>
                    );
                })}
			</div>
		</div>
	);
}

export default MultiChoiceRadio;