import './email_input.css';
import {ReactComponent as PassLogo} from './pass.svg';
import {ReactComponent as FailLogo} from './fail.svg';
import React, { useState, useEffect } from 'react';

interface AlphaInputProps {
    title: string
    setValidEmail: (value: string)=>void
}

const EmailInput = ({title, setValidEmail}: AlphaInputProps) => {

    const [email, setEmail] = useState('');
    const [emailValid, setEmailValid] = useState(false);

    const handleChange = (e) => {
        let v = e.target.value;

        setEmail(v);
        // if(setValidEmail && emailValid) setValidEmail(v);
    }

    useEffect(()=>{
        const re = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;
    	const emailValid = re.test(String(email).toLowerCase());
        if(emailValid) {
            setEmailValid(true);
            setValidEmail(email);
        } else {
            setEmailValid(false);
            if(setValidEmail) setValidEmail("");
        }
    }, [email, setValidEmail])

    return (
        <div className='EmailInputContainer'>
            <p className='EmailInputTitle'>{title}</p>
            <div className='EmailInputOptionContainer'>
                <input className='EmailInputOption' type="email" placeholder={"your@email.com"} onChange={handleChange}/>
                {emailValid ? <PassLogo className='EmailInputValidSVG'/> : <FailLogo className='EmailInputValidSVG'/>}
            </div>
        </div>
    )
}

export default EmailInput;