export enum InterestType {
    lender,
    borrower,
    both
}

export const RespondantInterestType = {
    question: "Are you interested in Lending, borrowing or both?",
    types: ["Lending", "Borrowing", "Both"]
}

export const OpenInterestString = {
    lender: "Are you open to lending money to individuals rather than traditional investment options?",
    borrower: "Are you open to borrowing from individuals rather than traditional financial institutions?"
}

export const LenderMultiChoiceQuestions = {
    title: "How much would you be interested in lending?",
    amounts: ["$0 to $1,000", "$1,000 to $5,000", "$5,000 to 20,000", "$20,000+"],
    durations: ["1 month", "bewteen 1 and 6 months", "between 6 and 12 months", "between 12 and 24 months", "more than 24 months"],
}

export const BorrowerMultiChoiceQuestions = {
    title: "How much would you be interested in borrowing?",
    altTitle: "How long would you be interested in borrowing?",
    amounts: ["$0 to $1,000", "$1,000 to $5,000", "$5,000 to 20,000", "$20,000+"],
    durations: ["1 month", "bewteen 1 and 6 months", "between 6 and 12 months", "between 1 and 2 years", "more than 2 years"],
}

export class QuestionnaireModel {
    interestType: number;
    comfortLending: boolean;
    comfortBorrowing: boolean;
    lendingAmount: number;
    lendingDuration: number;
    creditscore: number;
    emailAddress?: string;
}